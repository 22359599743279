import React, { useState } from 'react';


const AboutForm = () => {

    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        phone: '',
        course_type: '',
        qulification: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( 'https://universitybureau.com/content/scriptt.php' , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            console.log(result); // Handle the response as needed
            alert("Thankyou !");
        } catch (error) {
            console.error('Error:', error);
            alert("Please try again later !");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="wpcf7-form init">
            <input
                size="{40}"
                maxlength="{80}"
                className="wpcf7-form-control wpcf7-text kd-cta-2-form-input"
                placeholder="First Name"
                value={formData.f_name}
                onChange={handleChange}
                type="text"
                name="f_name"
            />
            <input
                size="{40}"
                maxlength="{80}"
                className="wpcf7-form-control wpcf7-text kd-cta-2-form-input"
                placeholder="Last Name"
                value={formData.l_name}
                onChange={handleChange}
                type="text"
                name="l_name"
            />
            <input
                size="{40}"
                maxlength="{80}"
                className="wpcf7-form-control wpcf7-text kd-cta-2-form-input has-full-width"
                placeholder="Course Type"
                value={formData.course_type}
                onChange={handleChange}
                type="text"
                name="course_type"
            />
            <input
                size="{40}"
                maxlength="{80}"
                className="wpcf7-form-control wpcf7-text kd-cta-2-form-input has-full-width"
                placeholder="Qualification"
                value={formData.qulification}
                onChange={handleChange}
                type="text"
                name="qulification"
            />
            <input
                size="{40}"
                maxlength="{80}"
                className="wpcf7-form-control wpcf7-text kd-cta-2-form-input has-full-width"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                type="text"
                name="phone"
            />
            <button
                type="submit"
                aria-label="name"
                className="kd-cta-2-form-btn kd-pr-btn-1 text-capitalize has-black has-full-width"
            >
                <span className="text" data-back="Submit" data-front="Submit" />
            </button>
            <div className="wpcf7-response-output" aria-hidden="true" />
        </form>
    );
};

export default AboutForm;
