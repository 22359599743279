import React, { useState } from 'react';


const ContactForm = () => {

    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( 'https://universitybureau.com/content/script.php' , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            console.log(result); // Handle the response as needed
            alert("Thankyou !");
        } catch (error) {
            console.error('Error:', error);
            alert("Please try again later !");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="wpcf7-form init">
            <div className="kd-contact-us-form">
                <input
                    size="40"
                    maxLength="80"
                    className="wpcf7-form-control wpcf7-text"
                    placeholder="First Name"
                    value={formData.f_name}
                    onChange={handleChange}
                    type="text"
                    name="f_name"
                />
                <input
                    size="40"
                    maxLength="80"
                    className="wpcf7-form-control wpcf7-text"
                    placeholder="Last Name"
                    value={formData.l_name}
                    onChange={handleChange}
                    type="text"
                    name="l_name"
                />
                <input
                    size="40"
                    maxLength="80"
                    className="wpcf7-form-control wpcf7-text"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    type="text"
                    name="phone"
                />
                <input
                    size="40"
                    maxLength="80"
                    className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    name="email"
                />
                <textarea
                    cols="40"
                    rows="10"
                    maxLength="400"
                    className="wpcf7-form-control wpcf7-textarea"
                    placeholder="Type your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                ></textarea>

                <div className="btn-wrap">
                    <button className="kd-contact-us-form-btn" type="submit" aria-label="name">
                        <span className="text" data-back="send message" data-front="send message"></span>
                        <span className="icon">
                            <i className="fa-solid fa-arrow-right"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div className="wpcf7-response-output" aria-hidden="true"></div>
        </form>
    );
};

export default ContactForm;
