import React, { useState } from 'react';


const Newsteller = () => {

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( 'https://universitybureau.com/content/news.php' , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            console.log(result); // Handle the response as needed
            alert("Thankyou !");
        } catch (error) {
            console.error('Error:', error);
            alert("Please try again later !");
        }
    };

    return (
        <form
            className=""
            onSubmit={handleSubmit} 
        >
            <div className="mc4wp-form-fields">
                <input
                className="kd-footer-1-form-input "
                type="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                name="email"
                />
                <button
                className="kd-footer-1-form-btn kd-pr-btn-1"
                type="submit"
                aria-label="name"
                >
                <span
                    className="text"
                    data-back="subscribe now"
                    data-front="subscribe now"
                />
                <span className="icon">
                    <i className="fa-solid fa-arrow-right" />
                </span>
                </button>
            </div>

        </form>

    );
};

export default Newsteller;
