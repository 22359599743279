import React , {useState} from "react";
import logo from "../../logo.png";
import canada from "../../assets/imgs/flags/canada.png";
import uk from "../../assets/imgs/flags/uk.png";
import usa from "../../assets/imgs/flags/usa.png";
import europe from "../../assets/imgs/flags/europe.png";
import { useModalStore } from '../include/modalUtils';
import FirstForm from "../include/FirstForm";

function Navbar() {

  const { isOpen, showForm, closeModal } = useModalStore();

  const { openModal } = useModalStore();

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleDiv = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const closeDiv = () => {
    setIsMenuVisible(false);
  };

  const handleLoginClick = () => {
    window.location.href = '/sign-in';
  };
  const handleRegisterClick = () => {
    window.location.href = '/sign-up';
  };

  const Void = (event) => {
    event.preventDefault(); 
  };


  return (
    <nav>
      <>
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-contenter" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Get Free Counseling Now!</h3>
              <button onClick={closeModal}>X</button>
            </div>
            {showForm && <FirstForm />} {/* Show form only after 4 seconds */}
          </div>
        </div>
      )}
        <div className="scroll-top">
          <div className="scroll-top-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="injected-svg"
              data-src="https://static.elfsight.com/icons/app-back-to-top-arrow-3.svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path d="m7.997 10 3.515-3.79a.672.672 0 0 1 .89-.076l.086.075L16 10l-3 .001V18h-2v-7.999L7.997 10z"></path>
            </svg>
          </div>
        </div>
        <div
          data-elementor-type="wp-post"
          data-elementor-id={17}
          className="elementor elementor-17"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-545d384 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="545d384"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-125cf92"
                data-id="125cf92"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-8c63406 elementor-widget elementor-widget-kadu-header"
                    data-id="8c63406"
                    data-element_type="widget"
                    data-widget_type="kadu-header.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-header-1-area txa_sticky_header">
                        {/* header-top */}
                        <div className="kd-header-1-top pt-20 pb-20">
                          <div className="container kd-container-1">
                            <div className="kd-header-1-top-row d-flex align-items-center ">
                              <a
                                href="/"
                                aria-label="name"
                                className="kd-header-1-logo-1 d-block"
                              >
                                <img
                                  className="logo_site-size"
                                  src={logo}
                                  alt=""
                                />
                              </a>
                              <div className="kd-header-1-top-contact-item d-none d-lg-flex align-items-center kd-ml-auto">
                                <div className="icon d-flex justify-content-center align-items-center">
                                  <i
                                    aria-hidden="true"
                                    className="far fa-envelope"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="subtitle kd-heading-1">
                                    For Support
                                  </h4>
                                  <a
                                    href="mailto:info@universitybureau.com"
                                    aria-label="name"
                                    className="link kd-heading-1 d-block text-lowercase cust-pad"
                                  >
                                    info@universitybureau.com
                                  </a>
                                  <a
                                    href="mailto:support@universitybureau.com"
                                    aria-label="name"
                                    className="link kd-heading-1 d-block text-lowercase cust-pad"
                                  >
                                    support@universitybureau.com
                                  </a>
                                </div>
                              </div>
                              <div className="kd-header-1-top-contact-item d-none d-lg-flex align-items-center ">
                                <div className="icon d-flex justify-content-center align-items-center">
                                  <i
                                    aria-hidden="true"
                                    className="fas fa-phone-alt"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="subtitle kd-heading-1">
                                    call us anytime
                                  </h4>
                                  <a
                                    href="tel:+91 93555-00042"
                                    aria-label="name"
                                    className="link kd-heading-1 d-block text-lowercase cust-pad"
                                  >
                                    +91 93555-00042
                                  </a>
                                  <a
                                    href="tel:+91 92051-90059"
                                    aria-label="name"
                                    className="link kd-heading-1 d-block text-lowercase cust-pad"
                                  >
                                    +91 92051-90059
                                  </a>
                                </div>
                              </div>
                              <a
                                aria-label="name"
                                onClick={openModal} 
                                className="kd-pr-btn-1 text-uppercase"
                              >
                                <span
                                  className="text"
                                  data-back="Let’s Talk"
                                  data-front="Not Sure?"
                                />
                                <span className="icon">
                                  <i className="fa-solid fa-arrow-right" />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* main-header */}
                        <div className="kd-header-1-main ">
                          <div className="container kd-container-1">
                            <div className="kd-header-1-main-row d-flex align-items-center justify-content-between">
                              {/* home-icon */}
                              <span className="kd-header-1-main-home-icon">
                                <i className="fa-solid fa-house" />
                              </span>
                              {/* menu */}
                              <nav className="main-navigation d-none d-lg-block kd-mr-auto">
                                <ul
                                  id="main-nav"
                                  className="nav navbar-nav clearfix"
                                >
                                  <li
                                    id="menu-item-1049"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-1049"
                                  >
                                    <a
                                      href="/"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      Home
                                    </a>
                                  </li>
                                  <li
                                    id="menu-item-1060"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1060"
                                  >
                                    <a
                                      href="/"
                                      onClick={Void}
                                      onclick="return true"
                                    >
                                      Study Destination
                                    </a>
                                    <ul className="dropdown-menu clearfix custom-dropdown">
                                      <li
                                        id="menu-item-2116"
                                        className="menu-item menu-item-type-post_type menu-item-object-page dropdown menu-item-2116"
                                      >
                                        <a
                                          href="/studydestinations/canada"
                                          onclick="return true"
                                        >
                                          <img alt="country" src={canada}></img>
                                          Canada
                                        </a>
                                      </li>
                                      <li
                                        id="menu-item-2112"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2112"
                                      >
                                        <a
                                          href="/studydestinations/europe"
                                          onclick="return true"
                                        >
                                          <img alt="country" src={europe}></img>
                                          Europe
                                        </a>
                                      </li>
                                      <li
                                        id="menu-item-1505"
                                        className="menu-item menu-item-type-post_type menu-item-object-page dropdown menu-item-1505"
                                      >
                                        <a
                                          href="/studydestinations/uk"
                                          onclick="return true"
                                        >
                                          <img alt="country" src={uk}></img>
                                          UK
                                        </a>
                                      </li>
                                      <li
                                        id="menu-item-2110"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2110"
                                      >
                                        <a
                                          href="/studydestinations/usa"
                                          onclick="return true"
                                        >
                                          <img alt="country" src={usa}></img>
                                          USA
                                        </a>
                                      </li>
                                      {/* <li
                                        id="menu-item-2111"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2111"
                                      >
                                        <a
                                          href="/studydestinations/australia"
                                          onclick="return true"
                                        >
                                          Australia
                                        </a>
                                      </li> */}
                                    </ul>
                                  </li>
                                  <li
                                    id="menu-item-2211"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2211"
                                  >
                                    <a href="/about" onclick="return true">
                                      Explore University Bureau
                                    </a>
                                  </li>
                                  <li
                                    id="menu-item-2108"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2108"
                                  >
                                    <a href="/contact" onclick="return true">
                                      Contact Us
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                              <div className="kd-header-1-action-link d-flex align-items-center justify-content-end">
                                {/* kd-search-btn */}
                                <button
                                  className="kd-search-btn-1 mob-d-none"
                                  type="button"
                                  onClick={handleLoginClick}
                                >
                                  <i aria-hidden="true" className="fas fa-user" />
                                  Login
                                </button>
                                <button
                                  className="kd-search-btn-1 mob-d-none"
                                  type="button"
                                  onClick={handleRegisterClick}
                                >
                                  <i class="fas fa-user-plus"></i>
                                  Register
                                </button>
                                {/* sidebar-btn */}
                                <button
                                  type="button"
                                  aria-label="name"
                                  className="ftc-mobile-menu-btn-1 gap-left"
                                  id="menuToggle"
                                  onClick={toggleDiv}
                                >
                                  <span />
                                  <span />
                                  <span />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`mobile-menu lenis lenis-smooth`}
                        id={`${isMenuVisible ? 'mobile-menu-sec-v' : 'mobile-menu-sec-h'}`}
                      >
                        <div className="mobile-menu-wrap">
                          <div className="mobile-menu-bg">
                            <span className="span1" />
                            <span className="span2" />
                          </div>
                          <div className="mobile-menu-logo-wrap mb-100">
                            <a
                              className="mobile-menu-logo d-block"
                              href="/"
                            >
                              <img src={logo} alt="Shape" />
                            </a>
                            <div
                              className="mobile-menu-close"
                              id="menuToggle2"
                              onClick={closeDiv}
                            >
                              <i className="fas fa-times" />
                            </div>
                          </div>
                          <div className="mobile-menu-inner">
                            {/* mobile-menu-list */}
                            <div className="mobile-menu-navigation mb-50">
                              <nav className="mobile-main-navigation  clearfix ul-li">
                                <ul
                                  id="main-nav"
                                  className="nav navbar-nav clearfix"
                                >
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor dropdown menu-item-1049">
                                    <a
                                      href="/"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      Home
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor dropdown menu-item-1049">
                                    <a
                                      href="/studydestinations/canada"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      Canada
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor dropdown menu-item-1049">
                                    <a
                                      href="/studydestinations/usa"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      USA
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor dropdown menu-item-1049">
                                    <a
                                      href="/studydestinations/uk"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      United kingdom
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-14 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor dropdown menu-item-1049">
                                    <a
                                      href="/studydestinations/europe"
                                      aria-current="page"
                                      onclick="return true"
                                    >
                                      Europe
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page dropdown menu-item-2211">
                                    <a href="/about" onclick="return true">
                                      Explore University Bureau
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2108">
                                    <a href="/contact" onclick="return true">
                                      Contact Us
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2108">
                                    <a
                                      href="http://portal.universitybureau.com/login"
                                      onclick="return true"
                                    >
                                      Login
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2108">
                                    <a
                                      href="http://portal.universitybureau.com/register"
                                      onclick="return true"
                                    >
                                      Register
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                            <div className="mobile-menu-socail-link">
                              <div className="mobile-menu-social-1 d-flex align-items-center flex-wrap">
                                <a href="https://www.facebook.com/universitybureau" aria-label="social link">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-facebook-f"
                                  />
                                </a>
                                <a href="https://www.instagram.com/universitybureau/" aria-label="social link">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-instagram"
                                  />
                                </a>
                                <a href="https://x.com/universitybure1" aria-label="social link">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="white" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/universitybureau/" aria-label="social link">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-linkedin"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </nav>
  );
}

export default Navbar;
